

import barba from '@barba/core';
import ScrollTrigger from '@terwanerik/scrolltrigger'
import anime from 'animejs';


export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.trigger = new ScrollTrigger()
            this.initailTrigger()
        });
        barba.hooks.leave((data) => {
            this.trigger.kill()
        });
        barba.hooks.after((data) => {
            this.trigger = new ScrollTrigger()
            this.initailTrigger()
        });

    }
    
    initailTrigger(){
        this.trigger.add('[data-trigger]', {
            once: true,
            offset: {
               
                viewport: {
                    x: 0,
                    y: (trigger, frame, direction) => {
                        return trigger.visible ? 0 : 0.3
                    }
                }
            }
        })
        this.trigger.add('[data-trigger="order-lists"]',
        { 
            once: true,
            offset: {
               
                viewport: {
                    y: (trigger, frame, direction) => {
                        return trigger.visible ? 0 : .2
                    }
                }
            },
            toggle: {
                callback: {
                    in: (trigger) => {
                        
                        const card = trigger.element.querySelectorAll('.js-scroll-order-lists');

                        anime({
                            targets: card,
                            translateY:['30px',0],
                            opacity:[0,1],
                            easing: 'easeOutExpo',
                            duration:1500,
                            delay: function(el, i) { return i * 800 }
                        });
                    }
                }
            }
        },
        )
        this.trigger.add('[data-trigger="order-lists2"]',
        { 
            once: true,
            offset: {
               
                viewport: {
                    y: (trigger, frame, direction) => {
                        return trigger.visible ? 0 : .2
                    }
                }
            },
            toggle: {
                callback: {
                    in: (trigger) => {
                        
                        const card = trigger.element.querySelectorAll('.js-scroll-order-lists2');

                        anime({
                            targets: card,
                            translateY:['40px',0],
                            opacity:[0,1],
                            easing: 'easeOutExpo',
                            duration:1000,
                            delay: function(el, i) { return i * 500 }
                        });
                    }
                }
            }
        },
        )
        this.trigger.add('[data-trigger="order-lists3"]',
        { 
            once: true,
            offset: {
               
                viewport: {
                    y: (trigger, frame, direction) => {
                        return trigger.visible ? 0 : .2
                    }
                }
            },
            toggle: {
                callback: {
                    in: (trigger) => {
                        
                        const card = trigger.element.querySelectorAll('.js-scroll-order-lists3');

                        anime({
                            targets: card,
                            translateY:['10px',0],
                            opacity:[0,1],
                            easing: 'easeOutExpo',
                            duration:1500,
                            delay: function(el, i) { return i * 400 }
                        });
                    }
                }
            }
        },
        )
        const strAnimations = document.querySelectorAll('[data-trigger="translateY"]')
        if(strAnimations.length > 0 ){
            strAnimations.forEach((el) => {
                let _str = el.textContent.split("");
                let str = "";
                _str.map( s => {
                    str += "<span class='str__animation'>" + s  + "</span>";
                })
                    
                el.innerHTML = str;
            })
        }

        this.trigger.add('[data-trigger="translateY"]',
            { 
                once: true,
                offset: {
                    viewport: {
                        y: (trigger, frame, direction) => {
                            return trigger.visible ? 0 : .1
                        }
                    }
                },
                toggle: {
                    callback: {
                        in: (trigger) => {
                            
                            const strs = trigger.element.querySelectorAll('span');

                            anime({
                                targets: strs,
                                translateX:['10%',0],
                                easing: 'easeOutSine',
                                duration: 800,
                                scale: [1.4,1],
                                opacity:[0,1],
                                delay: function(el, i) { return i * 20 }
                            });
            
                        }
                    }
                }
            },
        )
    }
}