
import barba from '@barba/core';
import Swiper from 'swiper';

export default class{

	constructor(){

        barba.hooks.beforeOnce((data) => {
            this.heroSlider()
            this.createSwiper()
            this.conceptSwiper()

        })
        barba.hooks.after((data) => {
            this.heroSlider()
            this.createSwiper()
            this.conceptSwiper()
        });
        
    }
    
    heroSlider(){
        new Swiper ('#hero--slider',{
            loop: true,
            effect :'fade',
            speed:2000,
            allowTouchMove :false,
            simulateTouch:false,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets'
            },
            autoplay: {
                delay: 4000
            }
        });
    }
    createSwiper(){
        new Swiper ('#recruit--slider', {
            slidesPerView: 1.2,
            // freeMode: true,
            // centeredSlides : true,
            scrollbar: {
                el: '.swiper-scrollbar',
                draggable: true,
            },
            spaceBetween: 0,
            breakpoints:{
                576: {
                    slidesPerView: 1.5,
                },
                650: {
                    slidesPerView: 1.5,
                },
                768: {
                    slidesPerView: 2.2,
                },
                992: {
                    slidesPerView: 3,
                }
            }
        });
    }
    conceptSwiper(){
        new Swiper ('#concept--slider', {
            loop: true,
            effect :'fade',
            speed:500,
            allowTouchMove :false,
            simulateTouch:false,
            autoplay: {
                delay: 500
            }
        });
    }
}