
import barba from '@barba/core';

export default class{

	constructor(){

        barba.hooks.afterOnce((data) => {
            this.init()
        })
        barba.hooks.after((data) => {
            this.init()
        });
        
    }
    
    init(){
        const companylinks=document.querySelectorAll("[data-company-link]")
        const companyimg=document.querySelector("[data-company-img]")
        if(companylinks.length > 0){
            companylinks.forEach(companylink=>{
                const name=companylink.dataset.companyLink
                companylink.addEventListener("mouseenter",()=>{
                    companyimg.classList.add(name)
                })
                companylink.addEventListener("mouseleave",()=>{
                    companyimg.classList.remove(name)
                })
            })
        }
        
        const recruitlinks=document.querySelectorAll("[data-recruit-link]")
        const recruitimg=document.querySelector("[data-recruit-img]")
        if(recruitlinks.length > 0){
            recruitlinks.forEach(recruitlink=>{
                const name=recruitlink.dataset.recruitLink
                recruitlink.addEventListener("mouseenter",()=>{
                    recruitimg.classList.add(name)
                })
                recruitlink.addEventListener("mouseleave",()=>{
                    recruitimg.classList.remove(name)
                })
            })
        }
    }
}