
import barba from '@barba/core';
import anime from 'animejs';

export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.init()
        })
        barba.hooks.after((data) => {
            this.init()
        });
    }
    init (){
        const heroCatchSvg = document.querySelector("#p-index__hero--catch-svg")
        if(heroCatchSvg){
            const loop1 = () => {
                anime({
                    targets:"#p-index__hero--catch-svg circle:nth-of-type(1)",
                    cx : () => {
                        return anime.random(-150,800)
                    },
                    cy : () => {
                        return anime.random(0,150)
                    },
                    easing:"linear",
                    duration: (el,i) => {
                        return anime.random(2200,2500)
                    },
                    complete:()=>{
                        loop1()
                    }
                })
            }
            const loop2 = () => {
                anime({
                    targets:"#p-index__hero--catch-svg circle:nth-of-type(2)",
                    cx : () => {
                        return anime.random(-150,800)
                    },
                    cy : () => {
                        return anime.random(0,150)
                    },
                    easing:"linear",
                    duration: (el,i) => {
                        return anime.random(2200,2500)
                    },
                    complete:()=>{
                        loop2()
                    }
                })
            }
            const loop3 = () => {
                anime({
                    targets:"#p-index__hero--catch-svg circle:nth-of-type(3)",
                    cx : () => {
                        return anime.random(-150,800)
                    },
                    cy : () => {
                        return anime.random(0,150)
                    },
                    easing:"linear",
                    duration: (el,i) => {
                        return anime.random(2200,2500) 
                    },
                    complete:()=>{
                        loop3()
                    }
                })
            }
            
            loop1()
            loop2()
            loop3()
        }
    }
}