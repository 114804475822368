
import barba from '@barba/core';

export default class{
    constructor(){
        this.tab_wrapper = '.js-tab'
        this.tab_item = '.js-tab__item'
        this.tab_pane = '.js-tab__pane'
        this.active_class = 'c-tab__active'
 
        this.keys = {   
            "left": 37,
            "up": 38,
            "right": 39,
            "down": 40
        }
        
        barba.hooks.afterOnce((data) => {
            this.init()
        })
        barba.hooks.after((data) => {
            this.init()
        });
    }
    init(){
        this.tab = document.querySelectorAll(this.tab_wrapper);
        this.tab_navs = [];
 
        this.hashPane = false
        if(window.location.hash){
            this.hashPane = document.querySelector(window.location.hash + this.tab_pane)
        }
        if(this.tab.length > 0){
            this.tab.forEach( (tab,i) => {
                const tab_items = tab.querySelectorAll( this.tab_item );
                this.tab_navs.push( tab_items )
 
                if(tab_items.length > 0){
                    tab_items.forEach( (tab_nav,index) => {

                        if(this.hashPane){
                            const hash = window.location.hash.replace('#','')
                            const tab_items_active = document.querySelector( this.tab_item + '.' + this.active_class );
                            const tab_nav = document.querySelector(this.tab_item + '[aria-controls="' + hash + '"]');
                            if(tab_items_active){
                                tab_items_active.classList.remove(this.active_class)
                            }
                            this._open(tab_nav,hash)
                        }else{
                            if(index == 0){
                                const pane_id = tab_nav.getAttribute('aria-controls');
                                this._open(tab_nav,pane_id)
                            }
                        }
                        this._addEvent(tab_nav,i,index,tab_items);
                    })
                }
            })
        }  
    
    }
 
    _addEvent (el,i,index,tab_items){
        
        el.addEventListener('click',(e) => {
            
            this._hide(i);
 
            const pane_id = el.getAttribute('aria-controls');
            this._open(el,pane_id)
        })
        el.addEventListener('keydown',(e)=>{
            const k = e.which || e.keyCode;
            let position = index
            if (k >= this.keys.left && k <= this.keys.down){
                if (k == this.keys.left || k == this.keys.up){
                    if (position > 0) {
                        e.preventDefault()
                        position--
                        tab_items[position].click()
                        tab_items[position].focus()
                    }
                }else if (k == this.keys.right || k == this.keys.down){
                    if (position < tab_items.length-1 ) {
                        e.preventDefault()
                        position++
                        tab_items[position].click()
                        tab_items[position].focus()
                    }
                }
            }
        })
    }
    _open(item,pane_id){
        const target_pane = document.querySelector( '#' + pane_id );
        if ( !target_pane ) return;
 
        item.classList.add(this.active_class);
        item.setAttribute("aria-selected",true)
        item.setAttribute("tabindex",0)
        target_pane.classList.add(this.active_class);
        target_pane.removeAttribute("hidden")
        
        if(window.outerWidth < 992){
            if( window.Sidebar ) {
                window.Sidebar.destroy()
            }
            if( window.IndexSidebar ) {
                window.IndexSidebar.destroy()
            }
        }else{
            if( window.Sidebar ) {
                window.Sidebar.updateSticky()
            }
            if( window.IndexSidebar ) {
                window.IndexSidebar.updateSticky()
            }
        }
    }
    _hide (i){
        const tab = this.tab[i]
        const item_active = tab.querySelector(this.tab_item+'.'+this.active_class);
        const pane_active = tab.querySelector(this.tab_pane+'.'+this.active_class);
        if(item_active){
            item_active.classList.remove(this.active_class);
            item_active.setAttribute("aria-selected",false)
            item_active.setAttribute("tabindex","-1")
        }
        if(pane_active){
            pane_active.classList.remove(this.active_class);
            pane_active.setAttribute("hidden","hidden")
        }

        if(window.outerWidth < 992){
            if( window.Sidebar ) {
                window.Sidebar.destroy()
            }
            if( window.IndexSidebar ) {
                window.IndexSidebar.destroy()
            }
        }else{
            if( window.Sidebar ) {
                window.Sidebar.updateSticky()
            }
            if( window.IndexSidebar ) {
                window.IndexSidebar.updateSticky()
            }
        }
    }
}
 
