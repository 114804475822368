
import barba from '@barba/core';
import gsap from 'gsap';

export default class {
    constructor(){
        this.accordion_wrapper = "js-accordion"
        this.accordion__trigger = "js-accordion__trigger"
        this.accordion__content = "js-accordion__content"
        this.active_class = 'c-accordion__active'

        this.keys = {   
            "left": 37,
            "up": 38,
            "right": 39,
            "down": 40
        }


        barba.hooks.afterOnce((data) => {
            this.init()
        })
        barba.hooks.after((data) => {

            this.init()
        });
    }
    init (){
        this.accordions = document.querySelectorAll('.' + this.accordion_wrapper)
        this.triggers = []
        this.contents = []


        if(this.accordions.length > 0){

            this.accordions.forEach( (accordion, i ) => {
                const triggers = accordion.querySelectorAll('.' + this.accordion__trigger)
                const contents = accordion.querySelectorAll('.' + this.accordion__content)
                this.triggers.push(triggers)
                this.contents.push(contents)

                
                if(triggers.length > 0){
                    triggers.forEach( (trigger, index) => {
                      this._addEvent(trigger,i,index,triggers,accordion);
                    })
                }
                if(contents.length > 0){
                    contents.forEach( (content, index) => {
                        if(!content.classList.contains(this.active_class)){
                            content.style.height = 0;
                        }
                    })
                }
            })
                
        }          
    }
    _addEvent (el,i,index,triggers,accordion){
        el.addEventListener('click',(e) => {
            e.preventDefault();
            const id = el.getAttribute('aria-controls')
            const contents = accordion.querySelector('#' + id)
            if(contents){
                if(!contents.classList.contains(this.active_class)){
                    this._close(el,contents);
                    e.currentTarget.classList.add(this.active_class);
                    this._animation(contents,'open');
                }else{
                    this._close(el,contents);
                }
                
            }
        })
        
        el.addEventListener('keydown',(e)=>{
            const k = e.which || e.keyCode;
            let position = index
            if (k >= this.keys.left && k <= this.keys.down){
                if (k == this.keys.left || k == this.keys.up){
                    if (position > 0) {
                        e.preventDefault()
                        position--
                        triggers[position].focus()
                    }
                }else if (k == this.keys.right || k == this.keys.down){
                    if (position < triggers.length-1 ) {
                        e.preventDefault()
                        position++
                        triggers[position].focus()
                    }
                }
            }
        })
    }
    _close (trigger,contents){

        if(trigger){
            trigger.classList.remove(this.active_class);
        }
        
        if(contents){
            contents.classList.remove(this.active_class);
            this._animation(contents,'close');
        }
    }
    _animation (el,type){

        if(type=="open"){
            el.classList.add(this.active_class);
            el.style.height = 'auto';
            let height = el.clientHeight;
            el.setAttribute('data-height',height);
            el.setAttribute('aria-hidden',false);
            el.style.height = 0;
            
            gsap.to(el,{
                height:height,
                duration:.3,
                ease: "expo.out",
                onComplete : ()=>{
                    // el.dispatchEvent(this.openedEvent);
                    if(window.outerWidth < 992){
                        if( window.Sidebar ) {
                            window.Sidebar.destroy()
                        }
                        if( window.IndexSidebar ) {
                            window.IndexSidebar.destroy()
                        }
                    }else{
                        if( window.Sidebar ) {
                            window.Sidebar.updateSticky()
                        }
                        if( window.IndexSidebar ) {
                            window.IndexSidebar.updateSticky()
                        }
                    }
                }
            })
        }else{
            
            el.classList.remove(this.active_class);
            el.setAttribute('aria-hidden',true);
            gsap.to(el,{
                height:0,
                duration:.3,
                ease: "expo.out",
                onComplete : ()=>{
                    if(window.outerWidth < 992){
                        if( window.Sidebar ) {
                            window.Sidebar.destroy()
                        }
                        if( window.IndexSidebar ) {
                            window.IndexSidebar.destroy()
                        }
                    }else{
                        if( window.Sidebar ) {
                            window.Sidebar.initialize()
                        }
                        if( window.IndexSidebar ) {
                            window.IndexSidebar.initialize()
                        }
                    }
                }
            })
        }
    }
}
